<template>
  <div class="number-input" :class="className">
    <v-text-field
      @keydown.enter="keydownEnterHandler"
      @change="changeHandler"
      :name="name"
      v-model="number"
      color="#122A47 "
      outlined
      hide-details
      :readonly="readonly"
      :disabled="disabled"
    >
      <v-btn
        v-if="showControl"
        @click="addProduct"
        slot="append"
        elevation="0"
        class="pa-3"
        min-width="30"
        min-height="30"
        max-width="30"
        max-height="30"
      >
        <i class="ri-add-line"></i>
      </v-btn>
      <v-btn
        v-if="showControl"
        :disabled="number <= 0"
        @click="minusProduct"
        slot="prepend-inner"
        elevation="0"
        class="pa-3"
        min-width="30"
        min-height="30"
        max-width="30"
        max-height="30"
      >
        <i class="ri-subtract-line"></i>
      </v-btn>

      <v-btn
        v-if="reload"
        slot="prepend-inner"
        elevation="0"
        color="primary"
        class="ma-0"
        @click="reloadHandler"
      >
        <i class="ri-restart-line"></i>
      </v-btn>
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
    },
    max: {
      type: Number,
      default: 9999999,
    },
    name: String,
    control: {
      type: Boolean,
      default: true,
    },
    reload: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 固定小數點後幾位
     */
    fixed: {
      type: Number,
      default: 0
    },
    rule: {
      type: Function,
    },
  },
  data: () => ({
    number: null,
  }),
  computed: {
    className() {
      if(this.reload) return 'number-input--reload'
      return null
    },
    // 有reload按鈕就不顯示+-按鈕
    showControl() {
      if (this.reload) return false;
      return this.control;
    },
    hasMax() {
      return this.max > -1;
    },
    computed_number() {
      // 套用自訂規則
      if(typeof this.rule == 'function') {
        const res =  this.rule(this.number, this.max)
        if(res != undefined) return res
      }

      if(this.number == null || undefined) return null

      let value = this.$fixedPrice(this.number, this.fixed)

      if (isNaN(value) === true) {
        return null;
      }
      if (this.hasMax && value > this.max) {
        this.$snotify.warning('已超過最大值')
        return window.eagleLodash.cloneDeep(this.max);
      }
      if (value < 0) {
        return null;
      }

      return value;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.number = this.$eagleLodash.cloneDeep(this.value);
      },
    },
    number: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.handleProductNumber();
          if (this.$eagleLodash.isEqual(this.number, this.value)) return;

          this.$emit("input", this.number);
        });
      },
    },
  },
  methods: {
    async handleProductNumber() {
      // console.log(this.number, this.computed_number)
      if (this.number == this.computed_number) return;
      // console.log('覆寫')
      this.number = this.computed_number;
    },
    addProduct() {
      let value = +this.number;
      this.number = value + 1;
      this.changeHandler(this.number);
    },
    minusProduct() {
      let value = +this.number;
      this.number = value - 1;
      this.changeHandler(this.number);
    },
    async keydownEnterHandler() {
      this.$emit("change", this.number);
    },
    changeHandler(value) {
      if(value === "") {
        this.$snotify.warning('請輸入數量')
        return
      }
      this.$emit("change", value);
    },
    reloadHandler() {
      this.$emit("reload");
    },
  },
};
</script>


<style lang="sass">
.number-input
  background: #ffffff
  border-radius: 5px
  flex: 1
  .v-input__control
    .v-input__prepend-inner,.v-text-field__slot,.v-input__append-inner
      margin: 6px 0 !important
      padding: 0px !important
      z-index: 50
    .v-input__slot
      min-height: 42px !important
      max-height: 42px !important
    .v-text-field__slot
      input
        text-align: center
    .v-text-field--outlined
      fieldset
        background: #ffffff
        border-width: 1px !important
  &--reload
    .v-input__slot
      padding: 0 !important
      .v-input__prepend-inner
        margin: 0 !important
        align-self: stretch !important
        .v-btn
          height: 100% !important
          min-width: 45px !important
          padding: 0 !important
          border-radius: 4px 0 0 4px !important
</style>